body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131313;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2e2e2eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070aa;
}

canvas {
  cursor: grab;
}
canvas:active {
  cursor: grabbing;
}
button {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.main {
  width: calc(100% - 10rem);
  height: 100vh;
  margin-left: 10rem;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.container {
  height: 100vh;
  scroll-snap-align: start;
  overflow: hidden;
}

.threed-container {
  width: calc(100% - 10rem);
}

.home {
  height: 100vh;
  /* background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png); */
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.mobile {
  display: none;
}
.menu-icon {
  color: #ffffffc7;
}

.footer {
  height: 30vh;
  color: #fff6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.highlight {
  color: #fff;
}
input:-internal-autofill-selected {
  background: #131313;
}
.t {
  cursor: default;
}
.t:hover {
  color: aqua;
}

@media only screen and (max-width: 1044px) {
  .main {
    width: 100vw;
    margin-left: 0;
  }
  .threed-container {
    width: 100vw;
    margin-left: 0;
  }
  .mobile {
    display: block;
  }
  .menu-icon {
    color: #ffffffb8;
    font-size: large;
    padding: 0.8rem;
    position: absolute;
    right: 0;
    z-index: 1000;
  }
  .menu-icon:hover {
    color: white;
  }
}
